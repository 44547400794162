import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  header: React.ReactNode;
  main: React.ReactNode;
  sidebar?: React.ReactNode;
}
export const BaseLayout: FC<Props> = ({ header, main, sidebar, children }) => {
  return (
    <div className="flex justify-between flex-col">
      <div className=" shadow-slate-100 shadow-sm">{header}</div>
      <div className="flex-grow md:flex min-h-[90vh]">
        {sidebar ? (
          <>
            <div className="p-4 md:w-1/4 shadow-sm shadow-slate-200 py-4 md:py-10">
              {sidebar}
            </div>
            <main className="p-4 md:p-10 md:w-3/4">{main}</main>
          </>
        ) : (
          // <main className="p-4 md:p-10 md:w-1/2 mx-auto md:mt-32">{main}</main>
          <main className="w-full">{main}</main>
        )}
      </div>
      {children}
      <footer className="bg-slate-50 pt-3">
        <div className="container mx-auto px-6 py-3 flex flex-wrap text-sm">
          Sorry, we were facing some issues. We have fixed it. If you like the
          product send us a{' '}
          <a href="mailto:hello@revrite.ai" className="text-blue-500 px-2">
            email{' '}
          </a>{' '}
          or tweet, fb post, share in your whatsapp group etc. 😄
        </div>
        <div className="container mx-auto px-6 py-3 flex justify-between content-center flex-wrap">
          <Link
            to="/"
            className="text-lg font-bold text-slate-500 hover:text-slate-400"
          >
            {' '}
            revrite.ai
          </Link>
          <p className="py-2 text-xs text-slate-500 sm:py-0">
            <a href="mailto:hello@revrite.ai">Contact us at hello@revrite.ai</a>
          </p>
          <p className="py-2 text-xs text-slate-500 sm:py-0">
            All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
};
