import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { RootApp } from './routes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { QueryClient, QueryClientProvider } from 'react-query';
import { register } from 'serviceWorkerRegistration';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(
  <React.StrictMode>
    {/* <QueryClientProvider client={queryClient}> */}
    <BrowserRouter>
      <div className="text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900">
        <RootApp />
      </div>
    </BrowserRouter>
    {/* </QueryClientProvider> */}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

register();
