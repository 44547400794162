import { Editor } from 'components/organisms/Editor';
import { Header } from 'components/organisms/Header';
import { BaseLayout } from 'components/templates/BaseLayout';

export const App = () => {
  return (
    <BaseLayout
      header={<Header />}
      main={<Editor />}
      // sidebar={<Sidebar />}
    >
      <main className="container mx-auto md:px-20 md:py-24 px-6 py-8">
        <h1 className="text-xl font-bold my-2">
          revrite.ai - AI based free paraphrasing tool with rich text format and
          multiple language support.
        </h1>
        <p className="w-9/10 md:w-3/4">
          Revrite.ai is an efficient online paraphrasing tool that enhances the
          quality of your message. You can easily paste your content, including
          emails, WhatsApp messages, Instagram posts, and research articles, and
          let our advanced artificial intelligence rephrase it into a more
          readable, clear, and simple format. Revrite is an ideal tool for
          crafting better messages for work, academic, and research projects.
          Our platform supports paraphrasing in over 100 languages, such as
          English, German, Spanish, Chinese, Russian, French, etc. We use
          OpenAI's ChatGPT to deliver top-notch results.!
        </p>
        <h2 className="text-lg font-bold my-2">Features of revrite.ai</h2>
        <ul className="w-9/10 md:w-3/4 list-disc list-inside">
          <li>Supports more than a hundred languages in the world</li>
          <li>
            Revrite has the ability to understand and rephrase entire
            paragraphs, leading to higher quality output than simply
            paraphrasing individual sentences.!
          </li>
          <li>
            Our content editor supports rich text editing, making it easy for
            you to copy and paste entire documents and rephrase them all at
            once. Try it out now in beta mode
          </li>
          <li>
            Easily rephrase text on-the-go with our mobile-friendly paraphrasing
            tool. No more worrying about typos with our reliable service. Say
            goodbye to error-filled messages and improve your writing today.!
          </li>
          <li>
            Our paraphrasing engine allows users to customize the output
            according to their preferences, making it a highly personalized tool
            for content creation and optimization.!
          </li>
          <li>
            Our platform is constantly improving with new features being added
            regularly. Some of the upcoming features include the ability to save
            documents, sentence paraphrasing, a history tracker for individual
            works, and the option to export your work. Stay tuned for these
            exciting updates!!
          </li>
          <li>
            At our current stage of development, which is beta, there is a
            possibility of encountering bugs. Now we support upto 500 words at a
            time for paraphrasing
          </li>
        </ul>
      </main>
    </BaseLayout>
  );
};
