import { Navbar } from 'flowbite-react';

export const Header = () => {
  return (
    <Navbar
      fluid={true}
      className="bg-slate-600 dark:bg-slate-800 dark:border-slate-700 border-slate-800"
    >
      <Navbar.Brand to="/" href="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold text-white">
          Revrite.ai
        </span>
      </Navbar.Brand>
      <a
        href="https://theresanaiforthat.com/ai/revrite/?ref=featured&v=123743"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img
          width="200"
          src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
          alt="Featured on The AI For That"
        />
      </a>
    </Navbar>
  );
};
