import React, { Suspense, useEffect, useState } from 'react';

const LazyEditor = React.lazy(() => import('./Editor'));
const LazyAnalytics = React.lazy(() => {
  return new Promise(resolve => {
    // @ts-ignore
    setTimeout(() => resolve(import('./Analytics')), 3500);
  });
});

export const Editor = () => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <Suspense
        fallback={
          <div className="h-[250px] outline-none py-[15px] px-2.5 resize-none text-ellipsis overflow-auto">
            Editor Loading...
          </div>
        }
      >
        <LazyEditor />
      </Suspense>
      <Suspense fallback={null}>{showComponent && <LazyAnalytics />}</Suspense>
    </>
  );
};
